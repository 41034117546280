:root {
    --viavi-dark: #001529;
    --viavi-bright: #fff;
}

.ngw-pyramid-layout-header {
    --primary: var(--viavi-bright);
    --link-color: var(--viavi-bright);
    --on-primary-text: var(--viavi-dark);
    background-color: var(--viavi-dark);

    > a > svg {
        width: 100px;
        margin: 12px 20px;
    }

    .ngw-viavi-launcher {
        display: block;
        color: var(--viavi-bright);
        text-decoration: none;
        border: 2px solid var(--viavi-bright);
        border-radius: 4px;
        line-height: 28px;
        padding: 0px 16px;
    }
}
